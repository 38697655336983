<!--
 * @Descripttion: 用户帐号列表
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-02 01:18:17
 * @LastEditTime: 2021-10-10 02:23:31
-->
<template>
  <div>
    <!-- <el-select class="select-list-index"
                 size="mini"
                 v-model="query.zz_cid"
                 @change="ZhongZhuChangeQuery"
                 placeholder="请选择种族">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhongzu"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
      <el-select class="select-list-index"
                 size="mini"
                 v-model="query.zy_cid"
                 @change="ZhiYeChangeQuery"
                 placeholder="请选择职业">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhiye"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
      <el-select labelInValue
                 class="select-list-index"
                 size="mini"
                 value-key="id"
                 v-model="query.sortStr"
                 @change="SortChangeQuery"
                 placeholder="请选择排序">
        <el-option label="价格升序"
                   :value="{id:'1',sort_field:'price',sort_type:'asc'}"></el-option>
        <el-option label="价格降序"
                   :value="{id:'2',sort_field:'price',sort_type:'desc'}"></el-option>
        <el-option label="时间升序"
                   :value="{id:'3',sort_field:'create_time',sort_type:'asc'}"></el-option>
        <el-option label="时间降序"
                   :value="{id:'4',sort_field:'create_time',sort_type:'desc'}"></el-option>
      </el-select>
      <el-button class="filter-item"
                 size="medium"
                 type="primary"
                 @click="search()">查询</el-button>
      <el-button class="filter-item"
                 size="medium"
                 @click="refresh()">刷新</el-button> -->

    <!-- 内容 -->

    <el-button class="filter-item"
               style="margin:10px"
               type="primary"
               @click="add()">添加账号</el-button>
    <div class="flex"
         style="min-height:630px">

      <el-table v-loading="loading"
                :data="GameRoleList"
                :stripe="true"
                border>
        <el-table-column prop="game_role_id"
                         label="编号"
                         width="60" />
        <el-table-column prop="title"
                         label="主装备"
                         width="150">
          <template slot-scope="scope">
            <span style="border: 1px solid #ff0000;color: red;border-radius: 3px;padding: 1px;"
                  v-if="scope.row.is_hot==1">急售</span>
            {{scope.row.title}}
          </template>
        </el-table-column>
        <el-table-column prop="ZH_Type"
                         label="帐号类型"
                         min-width="80"
                         show-overflow-tooltip />
        <el-table-column prop="qufu"
                         label="所在区服"> </el-table-column>
        <el-table-column label="职业"
                         min-width="90">
          <template slot-scope="{row}">
            {{row.zhongzu}}-{{row.zhiye}}
          </template>
        </el-table-column>
        <el-table-column prop="price"
                         min-width="100"
                         label="价格(元)">
          <template slot-scope="scope">
            <span v-if="scope.row.price < 10000"
                  style="color: black">{{
            scope.row.price | money
          }}</span>
            <span v-else-if="scope.row.price > 1000 && scope.row.price < 100000"
                  style="color: orange">{{ scope.row.price | money }}</span>
            <span v-else
                  style="color: red">{{ scope.row.price | money }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="create_time"
                         width="82"
                         label="添加时间" />

        <el-table-column prop="pass_time"
                         sortable="custom"
                         width="110"
                         label="审核时间">
          <template slot-scope="scope">
            <span v-if="scope.row.pass_time == '待审核'"
                  style="color: orange">
              {{ scope.row.pass_time }}
            </span>
            <span v-else>
              {{ scope.row.pass_time }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column prop="status"
                         width="80"
                         label="状态"
                         sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0"
                  style="color: orange">
              待审核
            </span>
            <span v-else-if="scope.row.status == 1"
                  style="color: green">
              审核通过
            </span>
            <span v-else-if="scope.row.status == 2"> 已售出 </span>
            <span v-else-if="scope.row.status == 3"
                  style="color: red">
              拒绝通过
              <div style="border: 1px solid #f7a8a8;
    color: #ffa500;">
                {{scope.row.reject_info}}
              </div>
            </span>

          </template>
        </el-table-column>
        <el-table-column label="操作"
                         min-width="90"
                         align="center"
                         fixed="right">
          <template slot-scope="{ row }">

            <el-button v-if="row.status != 2"
                       type="text"
                       size="small"
                       @click="edit(row)">修改</el-button>
            <el-button type="text"
                       size="small"
                       @click="deleGameRole(row)"
                       v-if="row.status !=2 ">删除</el-button>
            <el-button v-if="row.status == 2"
                       type="text"
                       size="small">已售出</el-button>
          </template>
        </el-table-column>

      </el-table>

    </div>
    <!-- 分页 -->
    <pagination v-show="count > 0"
                :total="count"
                :page.sync="query.page"
                :limit.sync="query.limit"
                @pagination="getGameRoleList"
                style="padding:10px" />
    <el-dialog class="dialog"
               :visible.sync="editDialog"
               :modal-append-to-body=false
               top="1vh">
      <el-form ref="ref"
               :rules="rules"
               :model="model"
               class="dialog-body"
               label-width="100px">
        <el-form-item label="帐号类型"
                      prop="zh_cid"
                      laceholder="请选择账号类型">
          <el-radio-group v-model="model.zh_cid"
                          v-for="(item, index) in ZH_type"
                          :key="index"
                          :index="index + ''">
            <el-radio :label="item.category_id"
                      style="margin-right: 1rem"
                      border
                      size="medium">{{ item.category_name }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="游戏区服"
                      prop="qf_cid"
                      clearable
                      placeholder="请选择游戏区服">
          <el-cascader v-model="model.qf_cid"
                       :options="qufu"
                       :props="{ value: 'category_id', label: 'category_name' }"
                       style="width: 100%"
                       clearable
                       filterable
                       placeholder="请选择游戏区服"
                       @change="QuFuChange" />
        </el-form-item>

        <el-form-item label="种族职业"
                      prop="zy_cid"
                      clearable
                      placeholder="请选择种族职业">
          <el-cascader v-model="model.zy_cid"
                       :options="zhiye"
                       :props="{ value: 'category_id', label: 'category_name' }"
                       style="width: 100%"
                       placeholder="请选择种族职业"
                       @change="ZhiYeChange"></el-cascader>
        </el-form-item>

        <el-form-item label="几变幻"
                      prop="title">
          <el-input v-model="model.title"
                    type="text"
                    maxlength="6"
                      show-word-limit
                    clearable
                    placeholder="请输入装备变幻，如：真14变幻" />
        </el-form-item>
        <el-form-item label="描述"
                      prop="description">
          <el-input v-model="model.description"
                    type="text"
                    clearable
                    placeholder="请输入描述" />
        </el-form-item>
        <el-form-item label="价格"
                      prop="price">
          <el-input v-model="model.price"
                    type="number"
                    clearable
                    placeholder="请输入出售价格，单位（元）" />
        </el-form-item>
        <el-form-item v-if="false"
                      label="联系人"
                      prop="contacts">
          <el-input v-model="model.contacts"
                    type="text"
                    clearable
                    placeholder="请输入联系人名称，例如：黄先生" />
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="contact">
          <el-input v-model="model.contact"
                    type="text"
                    clearable
                    placeholder="请输入联系方式，优先联系QQ" />
        </el-form-item>
        <el-form-item label="详细图文"
                      prop="content">
          <vue-ueditor-wrap v-model="model.content"
                            :config="{
            initialFrameHeight: 300,
              autoHeightEnabled:true,
              initialFrameWidth: '100%',
              UEDITOR_HOME_URL:'/static/ueditor/',
              zIndex: 999999,
            }"></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item label="是否急售"
                      prop="is_hot">
          <el-radio-group disabled
                          v-model="model.is_hot">
            <el-radio border
                      :label=1>急售</el-radio>
            <el-radio border
                      :label=0>非急售</el-radio>
          </el-radio-group>

          <el-tag style="margin-left: 1rem"
                  type="danger">温馨提示：急售请联系站长</el-tag>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary"
                   @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCateGory } from "@/api";
import { getGameRoleList, getGameRoleInfo } from "@/api/member";
import { addGameRole, editGameRole, deleGameRole } from "@/api/game_role";
import VueUeditorWrap from "vue-ueditor-wrap";
import Pagination from "@/components/Pagination";
import { MessageBox } from 'mint-ui';
export default {
  components: { Pagination, VueUeditorWrap },

  directives: {},

  data() {
    return {
      uid: "0",
      model: {
        game_role_id: "",
        content: "",
        zh_cid: 36,
        qf_cid: "",
        zy_cid: "",
        description: "",
        title: "",
        is_hot: "0",
        price: "",
        contacts: "",
        contact: "",
        status: "0",
        uid: "0",
      },
      rules: {
        zh_cid: [
          { required: true, message: "请选择账号类型", trigger: "change" },
        ],
        qf_cid: [
          { required: true, message: "请选择游戏区服", trigger: "blur" },
        ],
        zy_cid: [
          { required: true, message: "请选择种族职业", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入简介描述", trigger: "blur" },
        ],
        title: [
          {
            required: true,
            message: "请输入装备变幻，如：真14变幻",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入出售价格，单位（元）",
            trigger: "blur",
          },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
      loading: false,
      GameRoleList: [],
      editDialog: false,
      count: 0,
      query: {
        //查询分页
        page: 1,
        limit: 10,
      },
      ZH_type: [],
      qufu: [],
      zhiye: [],
    };
  },

  mounted() {
    if (this.$store.state.user.memberInfo) {
      // let memberInfo =this.$store.state.user.memberInfo;
      let memberInfo = JSON.parse(this.$store.state.user.memberInfo);
      this.memberInfo = memberInfo;
      this.uid = memberInfo.member_id;
    } else {

         this.$message.warning('请先登录！');
      this.$router.push({ path: "/login" });
    }
    this.getGameRoleList();
    this.getCateGory(); 
  },

  methods: {
    //获取帐号列表
    getGameRoleList() {
      this.loding = false;
      let params = {
        uid: this.memberInfo.member_id,
        qf_cid: this.$route.params.id,
        zy_cid: this.query.zy_cid,
        page: this.query.page,
        limit: this.query.limit,
      };

      getGameRoleList(params).then((res) => {
        //console.log(res.data.list);
        if (res.data.list.length != 0) {
          this.GameRoleList = res.data.list;
          this.count = res.data.count;
//console.log(this.GameRoleList);
          this.loding = true;
        } else {
          // console.log('没数据了')
          this.GameRoleList = res.data.list;
        }
      });
    },
    // 重置
    reset(row) {
      if (row) {
        this.model = row;
        if (this.model.game_role_id === 0) {
          this.model.game_role_id = "";
        }
      } else {
        this.model = this.$options.data().model;
      }
      if (this.$refs["ref"] !== undefined) {
        this.$refs["ref"].resetFields();
        this.$refs["ref"].clearValidate();
      }
    },
    // 添加
    add() {
      this.editDialog = true;
      this.dialogTitle = "添加";

      this.model = this.$options.data().model;
      //this.category()
    },
    //删除游戏角色
    deleGameRole(row) {
      //console.log(row);
      var title = " 删除-编号：" + row.game_role_id;
      var message =
        '确定要彻底删除<span style="color:red">' + row.title + " </span>吗？";


      if(this._isMobole()){
  
        MessageBox.confirm(message, title).then(() => {
 this.loading = true;
          deleGameRole({
            game_role_id: row.game_role_id,
          })
            .then((res) => {
              // console.log('成功')
              this.loading = false;
              this.getGameRoleList();
              this.reset();
              this.$message.success(res.msg);
            })
            .catch(() => {
              this.loading = false;
            });
}).catch(() => {});
      }else{
        this.$confirm(message, title, {
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.loading = true;
          deleGameRole({
            game_role_id: row.game_role_id,
          })
            .then((res) => {
              // console.log('成功')
              this.loading = false;
              this.getGameRoleList();
              this.reset();
              this.$message.success(res.msg);
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {});
      }

     
    },
    // 修改
    edit(row) {
      this.model.content = "";
      this.loading = true;
      this.editDialog = true;
      this.dialogTitle = "修改"; // + row.game_role_id;
      getGameRoleInfo({
        game_role_id: row.game_role_id,
      })
        .then((res) => {
          let row = res.data;
          delete row["ZH_Type"]
          delete row["zhiye"]
          delete row["zhongzu"]
          delete row["qufu"]
          delete row["zy_pid"]
          delete row["favorite"]
          //           console.log(row.indexOf('ZH_Type'));
          //           console.log(row.indexOf('zhiye'));
          // console.log(row.indexOf('zhongzu'));

          this.reset(row);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      // this.category()
    },
    //获取分类列表
    getCateGory() {
      //游戏类型分类
      var data = { pid: 35 }; 
      getCateGory(data)
        .then((res) => {
          this.ZH_type = res.data.clist;
        })
        .catch(() => {});
      //区服分类
      data = { pid: 23 };
      getCateGory(data)
        .then((res) => {
          this.qufu = res.data.clist;
        })
        .catch(() => {});
      //职业分类
      data = { pid: 24 };
      getCateGory(data)
        .then((res) => {
           // res.data.clist.pop()//移除最后一个数组，全职业
          
           res.data.clist.shift() //移除第一个数组
          this.zhiye = res.data.clist;
        })
        .catch(() => {});
    },
    // 父级选择
    QuFuChange(value) {
      if (value) {
        this.model.qf_cid = value[value.length - 1];
      }
    },
    ZhiYeChange(value) {
      if (value) {
        this.model.zy_cid = value[value.length - 1];
      }
    },
    // 取消
    cancel() {
      this.reset();
      this.editDialog = false;
    },
    //提交
    submit() {
      this.model.uid = this.uid;
      this.$refs["ref"].validate((valid) => {
        if (valid) {
          this.loading = true;

          if (this.model.game_role_id) {
            editGameRole(this.model)
              .then((res) => {
                // console.log('成功')
                this.editDialog = false;
                this.loading = false;
                this.getGameRoleList();
                this.reset();
                this.$message.success(res.msg);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            addGameRole(this.model)
              .then((res) => {
                this.loading = false;
                this.editDialog = false;
                this.getGameRoleList();
                this.reset();
                this.$message.success(res.msg);
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    _isMobole(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
    },
    //   //获获取职业分类列表
    // getZhongZu() {
    //   let params = {
    //     pid: 24,
    //     sort_field: "category_id",
    //     sort_type: "asc",
    //   };
    //   //24是职业的ID
    //   getCateGory(params).then((res) => {
    //     this.zhongzu = res.data.clist;
    //   });
    // },
    // getZhiYe() {
    //   console.log(this.query);
    //   let params = {
    //     pid: this.query.zz_cid,
    //     sort_field: "category_id",
    //     sort_type: "asc",
    //   };
    //   //24是职业的ID
    //   getCateGory(params).then((res) => {
    //     this.zhiye = res.data.clist;
    //   });
    // },
    // //获取分类信息
    // getCateGoryInfo() {
    //   let params = {
    //     pid: this.$route.params.id,
    //   };
    //   getCateGoryInfo(params).then((res) => {
    //     this.titleName = res.data.category_name;
    //   });
    // },
    // ZhongZhuChangeQuery(value) {
    //   if (value == 0) {
    //     this.zhiye = "";
    //     this.query.zy_cid = "";
    //   } else {
    //     this.query.zz_cid = value;
    //     this.getZhiYe();
    //   }
    // },
    // ZhiYeChangeQuery(value) {
    //   this.query.zy_cid = value;
    // },
    // //查询条件Change
    // SortChangeQuery(e) {
    //   this.query.sort_field = e.sort_field;
    //   this.query.sort_type = e.sort_type;
    // },
  },
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ".00"; //预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.celll {
  text-align: end !important;
}
</style> 